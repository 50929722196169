.property-listing-container {
  margin-top: 85px;
  min-height: 85vh;
  /* background-color: wheat; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-listing-banner {
  border-radius: 16px;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  margin-top: 16px;
}

.property-listing-banner-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.property-listing-title {
  font-size: 22px !important;
  font-weight: bold !important;
  background: linear-gradient(
    122.56deg,
    #e1a3ff 15.06%,
    #590db4 82.04%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

.property-listing-item {
  width: 100%;
  margin: 10px 0;
  padding: 10px 10px 24px 14px;
  min-height: 200px;
  box-shadow: 0px 1px 30.1px 0px rgba(74, 74, 74, 0.25);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 0.5px solid rgba(210, 210, 210, 1);
  border-radius: 32px;
}

/* ?Slider code  */

.slider-container {
  position: relative;
  width: 80%;
  margin: 50px auto;
}

.slick-slider {
  position: relative;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: black; /* Ensure icons are visible */
  cursor: pointer;
}

.slick-next::before {
  opacity: 0 !important;
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

.slick-disabled {
  opacity: 0.3;
  pointer-events: none;
}
